<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Cadastro de Chips</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar
                @search="buscar"
                :configFilter="{
                  listaSelect: item,
                  getItens: getItens,
                  jsonData: false,
                  switchStatus,
                  activeSwitchStatus: true,
                  isStatus,
                  switchStatusName: 'Desativados'
                }"
                :labelTextField="'Busca por número do chip:'" />
            </template>

            <template v-slot:item.chipCarrierId="{ item }">
              {{ textOperadora(item.chipCarrierId) }}
            </template>

            <template v-slot:item.chipContractId="{ item }">
              {{ textContrato(item.chipContractId) }}
            </template>
            
            <template v-slot:item.deleted="{ item }">
              <v-chip :color="getColor(item.deleted)" dark>{{ textStatus(item.deleted) }}</v-chip>
            </template>
            
            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit') && getStatus(item.deleted)"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete') && getStatus(item.deleted)"
                class="mr-2"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>

        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'Cadastro',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      {align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      {align: 'start', class: 'table-header', text: 'Nº do Chip', value: 'phoneNumber' },
      {align: 'start', class: 'table-header', text: 'ICCID', value: 'iccid' },
      {align: 'start', class: 'table-header', text: 'IMSI', value: 'imsi' },
      {align: 'start', class: 'table-header', text: 'Operadora', value: 'chipCarrierId' },
      {align: 'start', class: 'table-header', text: 'Contrato', value: 'chipContractId' },
      {align: 'center', class: 'table-header', text: 'Data Cadastro', value: 'date' },
      {align: 'center', class: 'table-header', text: 'Status', value: 'deleted', sortable: false },
      {align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters('chips', ['listaItens', 'totalItens', 'item', 'listCarriers', 'listContracts']),
    ...mapGetters('roles', ['permiteAcao']),
  },

  mounted () {
    this.getCarriers()
    this.getContracts()
  },

  methods: {
    ...mapActions('chips', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens', 'getCarriers', 'getContracts']),

    buscar(val) {
      this.loadingPag = true
      this.textSearch = val

      this.objCampoBusca['_filter'] = { phoneNumber_ilike: `%${val}%` }
      this
        .getItens({ _filter: { phoneNumber_ilike: `%${val}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },

    textOperadora (idOperadora) {
      const result = this.listCarriers.filter(item => item.value === idOperadora)[0]

      if (result) {
        return result.text
      }

      return ''
    },

    textContrato (idContratos) {
      const result = this.listContracts.filter(item => item.value === idContratos)[0]

      if (result) {
        return result.text
      }

      return ''
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
